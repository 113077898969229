.node {
  stroke: #777;
  stroke-width: 1.5px;
}

.node:hover {
  stroke: #000;
  cursor: pointer;
}

.dot {
  stroke: #777;
  stroke-width: 1.5px;
}

/* .link {
  stroke: #999;
  stroke-opacity: 0.6;
} */
.link {
  /* fill: none;
  stroke: rgb(201, 199, 199);
  stroke-width: 3px; */
  /* stroke: #c1c1c1;
  stroke-width: 1px;
  pointer-events: all; */
  fill: none;
  stroke: rgb(201, 199, 199);
  stroke-width: 1.5px;
}

/* path.link.ApplicationDevelopment {
  stroke: red;
}
path.link.SoftwareEngineering {
  stroke: purple;
} */

.tooltip {
  position: absolute;
  background-color: white;
  max-width: 200px;
  height: auto;
  padding: 1px;
  border-style: solid;
  border-radius: 4px;
  border-width: 1px;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.5);
  pointer-events: none;
}

.cell {
  fill: none;
  stroke: grey;
}

.cellText {
  fill: #000000;
  font-size: 11px;
  text-decoration: solid;
  opacity: 0.5;
}

.link-label {
  fill: #777;
  font-size: 10px;
  text-decoration: solid;
  opacity: 0.8;
}

div.tooltip {
  line-height: 1;
  font-weight: bold;
  padding: 12px;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  border-radius: 2px;
  pointer-events: none;
}

/* Creates a small triangle extender for the tooltip */
.tooltip:after {
  box-sizing: border-box;
  display: inline;
  font-size: 10px;
  width: 100%;
  line-height: 1;
  color: rgba(0, 0, 0, 0.8);
  position: absolute;
  pointer-events: none;
}

/* Northward tooltips */
.tooltip.n:after {
  content: "\25BC";
  margin: -1px 0 0 0;
  top: 100%;
  left: 0;
  text-align: center;
}

/* Eastward tooltips */
.tooltip.e:after {
  content: "\25C0";
  margin: -4px 0 0 0;
  top: 50%;
  left: -8px;
}

/* Southward tooltips */
.tooltip.s:after {
  content: "\25B2";
  margin: 0 0 1px 0;
  top: -8px;
  left: 0;
  text-align: center;
}

/* Westward tooltips */
.tooltip.w:after {
  content: "\25B6";
  margin: -4px 0 0 -1px;
  top: 50%;
  left: 100%;
}

.fGraphContainer {
  position: relative;
  width: 100%;
  height: 100%;
}
.jobDetail {
  display: flex;
  width: 250px;
  position: absolute;
  top: 50%;
  left: 3%;
  opacity: 0.6;
}
.rDetail {
  display: flex;
  width: 250px;
  position: absolute;
  top: 50%;
  right: 3%;
  opacity: 0.6;
}
.fGraph {
  position: relative;
  width: 100%;
  height: 100%;
}
.graphCol {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.graphCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: #f5f5f5;
  background-color: #000000;
  border-radius: 10px;
  box-shadow: 0px 0px 10px #f5f5f5;
}
.graphCard_lite {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: #000000;
  background-color: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0px 0px 10px #000000;
}
.graphCardTitle {
  font-size: 1.5em;
  font-weight: bold;
  color: #f5f5f5;
}
.graphCardTitle_lite {
  font-size: 1.5em;
  font-weight: bold;
  color: #000000;
}
.graphCardHeader {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.graphCardBody {
  font-size: 0.8em;
  color: #f5f5f5;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  align-self: center;
  width: 100%;
  padding-left: 10%;
  margin-top: -25 px;
}
.graphCardBody_lite {
  font-size: 0.8em;
  color: #000000;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  align-self: center;
  width: 100%;
  padding-left: 10%;
}
